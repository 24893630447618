if (module.hot) {
  module.hot.accept();
}

$(document).ready(function () {
  var header = $(".menu");
  headerHeight = $(header).height();
  console.log("[LEM] - INIT JS", headerHeight);  
  $(window).bind('scroll', function() {
      if ($(window).scrollTop() > headerHeight) {
        $(".mainContent").css("padding-top", headerHeight+"px");
        $('.menu').addClass('sticked');
      }
      else {
        $('.menu').removeClass('sticked');
        $(".mainContent").css("padding-top", "0");
      }
   });
});

// .mainContent padding
function gotoSection(className) {
  $("html, body").animate({ scrollTop: $('.'+className).offset().top-headerHeight }, 400);
}
//events
$(".voice a").click( function() {
    gotoSection($(this).data("linked"));
    $(".voice-wrapper").fadeOut();
} );
$(".hamburger").click( function() {
    $(".voice-wrapper").fadeIn();
} );
$(".closeMenu").click( function() {
    $(".voice-wrapper").fadeOut();
} );

$("form").submit(function(event){
  event.preventDefault(); //prevent default action 
	var post_url = $(this).attr("action"); //get form action url
	var request_method = $(this).attr("method"); //get form GET/POST method
  var form_data = $(this).serialize(); //Encode form elements for submission
  $("input,textarea").css("border" , "1px solid #A5A8AA");
  $("input,textarea").closest(".fw").removeClass("errored");
  $("input,textarea").closest(".fw").find(".errors").html("");
  $(".button_send").attr("disabled", true);
  
  $.ajax({
    type: "POST",
    url: post_url,
    data: form_data,
    success: function(msg){
      alert("Richiesta inviata correttamente!");
      $(".button_send").attr("disabled", false);
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
       var jsonResponse = JSON.parse(XMLHttpRequest.responseText);
       if(jsonResponse){
        $.each(jsonResponse.errors , function(key, value) {
            console.log(key, value);
            $("[name='"+ key +"']").css("border" , "1px solid red");
            $("[name='"+ key +"']").closest(".fw").addClass("errored");
            $("[name='"+ key +"']").closest(".fw").find(".errors").html(value);
        });
        $(".button_send").attr("disabled", false);
       }
    }
  });
  
});

